/*
 * @Author: 马明昌 m17319036829@163.com
 * @Date: 2024-10-23 17:23:52
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-10-24 16:59:36
 * @FilePath: \beipaijingu\pc\pc-center-web\src\constants\certificate.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 审核状态
export const CERTIFICATE_APPLY_AUDIT_STATUS_ENUM = {
  CHECKING: {
    value: 0,
    desc: '待审核'
  },
  COMPLETE: {
    value: 1,
    desc: '已审核通过'
  },
  REJECT: {
    value: 2,
    desc: '已驳回'
  }
};

// 发证状态
export const ISSUING_CERTIFICATE_STATUS_ENUM = {
  CHECKING: {
    value: 0,
    desc: '待发证'
  },
  COMPLETE:{
    value: 1,
    desc: '已发证'
  }
};

// 证书类型
export const CERTIFICATE_TYPE_ENUM = {
  ZHONG_YI_TU_SHOU_KANG_FU_TIAO_LI_JI_SHU: {
    value: 1,
    desc: '中医徒手康复技术'
  },
  ZHONG_YI_SHAN_SHI_YANG_SHENG_TIAO_LI_JI_SHU: {
    value: 2,
    desc: '中医膳食养生调理技术'
  },
  ZHONG_YI_ZHEN_JIU_ZHI_LIAO__JI_SHU: {
    value: 3,
    desc: '中医针灸治疗技术'
  },
  ZHONG_YI_FANG_YAO_ZHI_LIAO_JI_SHU: {
    value: 4,
    desc: '中医方药治疗技术'
  }
};

// 级别
export const CERTIFICATE_LEVEL_ENUM = {
  BEGINNER: {
    value: 1,
    desc: '初级'
  },
  INTERMEDIATE: {
    value: 2,
    desc: '中级'
  },
  ADVANCED: {
    value: 3,
    desc: '高级'
  }
};

export const CATEGORY_TYPE_ENUM = {
  // ZHONG_YI_KANG_FU_LI_LIAO_JI_SHU: {
  //   value: 900,
  //   desc: '中医康复理疗技术'
  // },
  // ZHONG_YI_ZHEN_JIU_JI_SHU: {
  //   value: 1000,
  //   desc: '中医针灸技术'
  // },
  // ZHONG_YI_NEI_FU_FANG_YAO_JI_SHU: {
  //   value: 1001,
  //   desc: '中医内服方药技术'
  // },
  ZHONG_YAO_PAO_ZHI_JI_SHU: {
    value: 1030,
    desc: '中药炮制技术'
  },
  ZHONG_YI_YANG_SHENG_MEI_RONG: {
    value: 1031,
    desc: '中医养生美容'
  },
  MU_YING_HU_LI_JI_SHU: {
    value: 1032,
    desc: '母婴护理技术'
  },
  ZHONG_YI_YAO_SHAN_YANG_SHENG_JI_SHU:{
    value: 901,
    desc: '中医药膳养生技术'
  },
  ZHONG_YI_JIN_GU_TIAO_LI_JI_SHU: {
    value: 1033,
    desc: '中医筋骨调理技术'
  },
  ZHONG_YI_XUE_WEI_TIE_FU_JI_SHU: {
    value: 1034,
    desc: '中医穴位贴敷技术'
  },
  ZHONG_YI_XIAO_ER_TUI_NA: {
    value: 1035,
    desc: '中医小儿推拿'
  },
  ZHONG_YI_KANG_FU_LI_LIAO: {
    value: 1036,
    desc: '中医康复理疗'
  },
  ZHONG_YI_YU_FANG_BAO_JIAO_TIAO_LI: {
    value: 1037,
    desc: '中医预防保健调理'
  },
  ZHONG_YI_GUA_SHA: {
    value: 1038,
    desc: '中医刮痧'
  },
  ZHONG_YI_JING_LUO_TIAO_LI: {
    value: 1039,
    desc: '中医经络调理'
  },
  ZHONG_YI_MAI_XIAN_SHI: {
    value: 1040,
    desc: '中医埋线师'
  },
  ZHONG_YI_GUAN_LIAO: {
    value: 1041,
    desc: '中医罐疗'
  },
  ZHONG_YI_RU_FANG_BAO_JIAN: {
    value: 1042,
    desc: '中医乳房保健'
  },
  YUN_CHAN_FU_XIN_LI_SHU_DAO: {
    value: 1043,
    desc: '孕产妇心理疏导'
  },
  ZHONG_YI_YUN_CHAN_FU_SHAN_SHI_TIAO_LI: {
    value: 1044,
    desc: '中医孕产妇膳食调理'
  },
  ZHONG_YI_CHAN_HOU_KANG_FU: {
    value: 1045,
    desc: '中医产后康复'
  },
  ZHONG_YI_TI_ZHI_TIAO_LI: {
    value: 1046,
    desc: '中医体质调理'
  },
  ZHONG_YI_HUO_LIAO: {
    value: 1047,
    desc: '中医火疗'
  },
  ZHONG_YI_CI_XUE_LI_LIAO: {
    value: 1048,
    desc: '中医刺血理疗'
  },
  ZHONG_YI_JIAN_KANG_GUAN_LI: {
    value: 1049,
    desc: '中医健康管理'
  },
  YUN_FU_SHAN_SHI_TIAO_LI: {
    value: 1050,
    desc: '孕产妇膳食调理'
  },
  ZHONG_YI_CUI_RU_JI_SHU: {
    value: 1051,
    desc: '中医催乳技术'
  },
  YI_JIN_JING: {
    value: 1052,
    desc: '易筋经'
  },
  ZHONG_YI_ER_XUE_JI_SHU: {
    value: 1053,
    desc: '中医耳穴技术'
  },
  SHA_DAI_XUN_JING_PAI_DA_LIAO_FA: {
    value: 1054,
    desc: '沙袋循经拍打疗法(省级非遗)'
  },
  WU_YUN_LIU_QI_LIN_CHUANG_YING_YONG: {
    value: 1055,
    desc: '五运六气临床应用'
  },
  ZHONG_YI_FENG_LIAO_KANG_FU: {
    value: 1056,
    desc: '中医蜂疗康复'
  },
  ZHONG_YI_FU_KE_SI_MI_HU_LI: {
    value: 1057,
    desc: '中医妇科私密护理'
  },
  ZHONG_YI_YAO_JI: {
    value: 1058,
    desc: '中医药剂'
  },
  YAN_JIAN_KANG_TIAO_LI_JI_SHU: {
    value: 1059,
    desc: '眼健康调理技术'
  },
  ZHONG_YI_BAO_JIAN_AN_MO: {
    value: 1060,
    desc: '中医保健按摩'
  }
};

export default {
  ISSUING_CERTIFICATE_STATUS_ENUM,
  CERTIFICATE_APPLY_AUDIT_STATUS_ENUM,
  CERTIFICATE_TYPE_ENUM,
  CERTIFICATE_LEVEL_ENUM,
  CATEGORY_TYPE_ENUM
};
